<template>
  <div id="buy-from-jci-list">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-dialog v-model="PreviewRecordPrompt" persistent width="auto">
        <buy-from-jci-preview
          pageDescription="Buy From JCI Preview"
          :PreviewRecordPrompt="PreviewRecordPrompt"
          :recordData="selectedData"
          @hideDialog="hidePreviewRecordPrompt"
          v-if="PreviewRecordPrompt"
        ></buy-from-jci-preview>
      </v-dialog>

      <v-card-text>
        <v-row wrap>
          <v-col align="center" cols="12">
            <v-btn
              @click.prevent="addData"
              elevation="30"
              color="primary"
              class="font-size-h6 fsize-3 mr-3 mt-5 my-3 white--text"
            >Submit Sales Info</v-btn>
            <v-btn
              @click.prevent="refreshPageData"
              elevation="30"
              color="warning"
              class="font-size-h6 fsize-3 mr-3 mt-5 my-3 white--text"
            >Referesh</v-btn>
          </v-col>
        </v-row>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
        </div>

        <br />

        <v-tabs
          v-model="tab"
          background-color="#8950FC"
          centered
          dark
          icons-and-text
          show-arrows
          v-if="tableData1.length > 0 || tableData.length > 0"
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#tab-1" :disabled="TabDisable2">
            Your Purchase Info
            <v-icon>mdi-basket</v-icon>
          </v-tab>

          <v-tab href="#tab-2" :disabled="TabDisable1">
            Your Sales Info
            <v-icon>mdi-store</v-icon>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" v-if="tableData1.length > 0 || tableData.length > 0">
          <v-tab-item value="tab-1">
            <v-card flat>
              <p></p>
              <v-container class="py-0" v-if="tableData.length == 0">
                <br />
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <h4>No Records found.</h4>
                  </v-col>
                </v-row>
              </v-container>
              <v-container class="py-0" v-if="tableData.length > 0">
                <br />
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <h4>{{ tableData.length }} Records found.</h4>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                    <br />
                    <v-data-table
                      class="elevation-1"
                      v-model="selected"
                      :search="search"
                      :item-key="tableOptions.ItemKey"
                      :single-select="tableOptions.SingleSelectFlag"
                      :headers="tableColumns"
                      :items="tableData"
                      :items-per-page="tableOptions.ItemsPerPage"
                      :footer-props="tableOptions.FooterProps"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-tooltip bottom v-if="item.PreviewFlag">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              icon
                              size="25px"
                              color="primary"
                              @click="previewData(item, e)"
                              v-bind="attrs"
                              v-on="on"
                            >mdi-file-search</v-icon>
                          </template>
                          <span>Preview</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-tab-item>

          <v-tab-item value="tab-2">
            <v-card flat>
              <p></p>
              <v-container class="py-0" v-if="tableData1.length == 0">
                <br />
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <h4>No Records found.</h4>
                  </v-col>
                </v-row>
              </v-container>
              <v-container class="py-0" v-if="tableData1.length > 0">
                <br />
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <h4>{{ tableData1.length }} Records found.</h4>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <v-text-field
                      v-model="search1"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                    <br />
                    <v-data-table
                      class="elevation-1"
                      v-model="selected1"
                      :search="search1"
                      :item-key="tableOptions1.ItemKey"
                      :single-select="tableOptions1.SingleSelectFlag"
                      :headers="tableColumns1"
                      :items="tableData1"
                      :items-per-page="tableOptions1.ItemsPerPage"
                      :footer-props="tableOptions1.FooterProps"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-tooltip bottom v-if="item.PreviewFlag">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              icon
                              size="25px"
                              color="primary"
                              @click="previewData(item, e)"
                              v-bind="attrs"
                              v-on="on"
                            >mdi-file-search</v-icon>
                          </template>
                          <span>Preview</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="item.DeleteFlag">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              icon
                              size="25px"
                              color="red"
                              @click="deleteConfirmAlert(item, e)"
                              v-bind="attrs"
                              v-on="on"
                            >mdi-delete</v-icon>
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import BuyFromJciPreview from "@/view/pages/erp/buy-from-jci/BuyFromJCIPreview";

export default {
  mixins: [common],
  components: {
    BuyFromJciPreview
  },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      ProgressLoadingFlag: false,
      SubmitFlag: false,

      tab: null,
      TabDisable1: false,
      TabDisable2: false,

      progessStart: 0,

      PreviewRecordPrompt: false,

      tableData: [],
      tableColumns: [],
      tableOptions: [],
      selected: [],
      search: "",

      tableData1: [],
      tableColumns1: [],
      tableOptions1: [],
      selected1: [],
      search1: "",

      ZoneCode: "",
      LomCode: "",
      MemberId: "",
      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: ""
    };
  },
  computed: {},
  mounted() {},
  watch: {
    PageInfo: function() {
      console.log("watch PageInfo");
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.LoadingFlag = false;
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        var MemberId = this.$session.get("MemberId");
        this.MemberId = MemberId;
        console.log({ MemberId });

        var ZoneId = this.$session.get("ZoneId");
        this.ZoneCode = ZoneId;
        console.log({ ZoneId });

        var LomId = this.$session.get("LomId");
        this.LomCode = LomId;
        console.log({ LomId });

        this.searchForm();
        this.searchForm1();
      }
    }
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "buy_from_jci",
        Action: "list"
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    addData() {
      console.log("addData called");
      // this.pageRedirect({}, "/order/success");
      this.$router.push("/buy-from-jci/create");
    },
    resetForm() {
      this.$refs.form1.reset();
      this.Buyer = {};
      this.BuyerFlag = false;
      this.Profession = {};
      this.ProfessionFlag = false;
    },
    backPage() {
      this.$router.go(-1);
    },
    searchForm() {
      console.log("searchForm is called");

      var MemberId = this.MemberId;
      console.log("MemberId=" + MemberId);

      if (MemberId != "") {
        this.LoadingFlag = true;
        this.tableData1 = [];

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/buy-from-jci/lists";
        var upload = {
          UserInterface: 1,
          Member: MemberId
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            var options = records.TableOptions;
            console.log({ options });
            thisIns.tableOptions = options;

            if (flag == 1) {
              thisIns.tableColumns = records.TableHeader;
              thisIns.tableData = records.TableData;
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "";
        if (MembershipId == "") {
          message += "Membership Id should't be empty. ";
        }
        this.toast("error", message);
      }
    },
    searchForm1() {
      console.log("searchForm is called");

      var MemberId = this.MemberId;
      console.log("MemberId=" + MemberId);

      if (MemberId != "") {
        this.LoadingFlag = true;
        this.tableData1 = [];

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/buy-from-jci/lists";
        var upload = {
          UserInterface: 2,
          Member: MemberId
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            var options = records.TableOptions;
            console.log({ options });
            thisIns.tableOptions1 = options;

            if (flag == 1) {
              thisIns.tableColumns1 = records.TableHeader;
              thisIns.tableData1 = records.TableData;
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "";
        if (MembershipId == "") {
          message += "Membership Id should't be empty. ";
        }
        this.toast("error", message);
      }
    },
    previewData(tr) {
      console.log("previewData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        this.PreviewRecordPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one data to edit";
        this.toast("error", message);
      }
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.PreviewRecordPrompt = false;
    },
    deleteConfirmAlert(tr) {
      console.log("deleteConfirmAlert called");
      var message = "";
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        var htmlTxt = "";
        htmlTxt += "<br/> Buyer Information <b> " + tr.BuyerInfo + "</b>";
        htmlTxt += "<br/> Company Name <b> " + tr.CompanyName + "</b>";
        htmlTxt += "<br/> Transation Value <b> " + tr.TransactionValue + "</b>";

        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Delete`,
          confirmButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#3085d6"
        }).then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.deleteData(tr);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        if (n1 == 0) {
          message += "Kindly select one record to delete. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    deleteData(tr) {
      console.log("deleteData is called");

      var BuyFromJciId = tr.BuyFromJciId;
      var BuyerMemberId = tr.BuyerMemberId;
      var SellerMemberId = tr.SellerMemberId;
      console.log(
        "BuyFromJciId=" +
          BuyFromJciId +
          "; BuyerMemberId=" +
          SellerMemberId +
          "; SellerMemberId=" +
          BuyerMemberId
      );

      if (BuyFromJciId > 0 && BuyerMemberId > 0 && SellerMemberId > 0) {
        this.ProgressLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/buy-from-jci/delete";
        var upload = {
          UserInterface: 1,
          BuyFromJci: BuyFromJciId,
          BuyerMember: BuyerMemberId,
          SellerMember: SellerMemberId
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.ProgressLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.searchForm();
              thisIns.searchForm1();
              thisIns.sweetAlert("success", output, true);
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            this.toast("error", message);
            thisIns.ProgressLoadingFlag = false;
          });
      } else {
        var message = "Buy From Jci Id should not be empty. ";
        this.sweetAlert("error", message, false);
      }
    }
  },
  beforeMount() {
    this.refreshPageData();
  }
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */
#buy-from-jci-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .animate-button1 {
    color: white;

    animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 10s; /* don't forget to set a duration! */
  }
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>